import React, { useState } from "react";
import { ProductsSectionQuery } from "./Query";
import Img from 'gatsby-image';
import Slider from "react-slick";
import ArrowSlider from './../../../../svg/SliderArrow';

const Products = props => {
    const { nodes } = ProductsSectionQuery();
    const sectionId = props.sectionId;
    let data = [];
    let sidebarItems = [];
    let activeProducts = [];
    const [ activeCategory, setActiveCategory ] = useState('wytrawne');

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el]) : null; });

    function getUnique(arr, comp) {

      const unique = arr
        .map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    
       return unique;
    }

    data[0].products_list.map(item => {
      item.acf.category.map(cat => {
        sidebarItems = [...sidebarItems, {
          name: cat.name,
          slug: cat.slug
        }]
      })
    })

    data[0].products_list.map(item => {
      item.acf.category.map(cat => {
        if(cat.slug === activeCategory) {
          activeProducts = [...activeProducts, {
            category: item.acf.category,
            editor: item.acf.editor,
            icons: item.acf.icons
          }]
        }
      })
    })

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };

    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div className={className + " nextArrow"}  onClick={onClick} >
            <ArrowSlider />
            <div className="line"></div>
        </div>
      );
    }
    
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
          <div className={className + " prevArrow"}  onClick={onClick} >
              <ArrowSlider />
              <div className="line"></div>
          </div>
      );
    }

    

    return (
        <section className="products-section">
          <h2>{ data[0].header }</h2>
          <div className="product-slider">
            <Slider { ...sliderSettings }>
              {activeProducts && activeProducts.map((item, index) => (
                <>
                <div className="menu-sidebar">
                  {getUnique(sidebarItems, 'name').map((item, index) => (
                    <span className={ activeCategory == item.slug ? "active" : ""} onClick={ () => setActiveCategory(item.slug) } key={ index }>{ item.name }</span>
                  ))}
                </div>
                <div className="product-item">
                  {item.editor &&
                    <div className="editor" dangerouslySetInnerHTML={ { __html: item.editor } } />
                  }
                  <div className="icons">
                    {item.icons && item.icons.map((item, index) => (
                      <Img fluid={ item.icon.localFile.childImageSharp.fluid } alt={ item.icon.alt_text } />
                    ))}
                  </div>
                </div>
                <div className="pagination">
                  <span> <p className="actual-number">{ ++index }</p> <p className="max-number">{ '/' + activeProducts.length }</p> </span>
                </div>
                </>
              ))}
            </Slider>
          </div>
        </section>
    )
}

export default Products;