import React, { useState, useEffect } from "react";
import { MapSectionQuery } from "./Query";
import Img from "gatsby-image"
import Pick from "../../../../svg/PickMap"
import PickMap from "../../../../images/pickMap.png"
import MapSettings from "./MapSettings";
import { useStaticQuery, graphql } from "gatsby"

const Map = props => {
    const { nodes } = MapSectionQuery();
    const sectionId = props.sectionId;
    let data = [];

    nodes.map(el => { return el.id === sectionId ? (data = [...data, el.shops]) : null; });
    let shops = data[0];
    const [ inputValue, setInputValue ] = useState('');
    const [ results, setResults ] = useState(null);
    const [ centerMap, setCenterMap ] = useState({lat: 49.724910,lng: 18.901850})
    let   [ maxResults, setMaxResults ] = useState(2);
    let   [ load, setLoad ] = useState(0);

    const images = useStaticQuery(graphql`
      query {
        bottom: file(relativePath: { eq: "arrow-bottom.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        exit: file(relativePath: { eq: "exit.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    const changeHandle = (value) => {
      if(value.length >= 3) {
      }else{
        setResults();
        setMaxResults(2);
      }
    }

    useEffect(() => {
      changeHandle(inputValue);
    }, [inputValue])

    const clearInput = () => {
      setInputValue('');
      changeHandle('');
    }
    
    const resultsText = (results) => {
      if(results && results != 0) {
        let lengthResults = results.length;
        if(lengthResults === 1) {
          return "<span>" + lengthResults + " lokalizacja</span>";
        }else if(lengthResults >= 3 && lengthResults < 5) {
          return "<span>" + lengthResults + " lokalizacje</span>";
        }else {
          return "<span>" + lengthResults + " lokalizacji</span>";
        }
      }
    }

    const actionIcon = (results) => {
      if(results || inputValue && !results) {
        return <div className="clear" onClick={() => clearInput()}><Img fluid={images.exit.childImageSharp.fluid} /></div>;
      }else{
        return <Img fluid={images.bottom.childImageSharp.fluid} />
      }
    }

    const centerMapItem = (lat, lng) => {
      setCenterMap({
        lat: lat,
        lng: lng
      })
    }

    const scrollTo = () => {
      console.log('scroll')
      let el = document.querySelector('#gdzie-kupic');
      let topPos = el.offsetTop -100;
      document.documentElement.scrollTop = topPos;
    }

    const itemClick = (lat, lng) => {
      centerMapItem(lat, lng);
      scrollTo();
    }
    return (
      <section className="map-section" id="gdzie-kupic">
        <div className="map">
          <MapSettings
            shops={ shops }
            inputValue={ inputValue }
            icon={ PickMap }
            resultItems={ (items) => setResults(items) } 
            centerMap={ centerMap }
          />
          <div className="box">
            <div className={ results || inputValue && !results ? "text-box none" : "text-box" }>
              <h2>Gdzie kupisz</h2>
              <p>Wybierz swoją miejscowość z listy i sprawdź czy mamy nasz produkt w Twojej okolicy.</p>
            </div>
            <div className="box-input">
              <input onChange={ (e) => setInputValue(e.target.value) } value={inputValue} type="text" />
              <label><Pick />{ results || inputValue && !results ? inputValue : "Twoja miejscowość" } <div className="count" dangerouslySetInnerHTML={ { __html: resultsText(results) } } />{ actionIcon(results) }</label>
            </div>
            {results && inputValue && results.map((item, index) => {
              if(index < maxResults) {
                return (
                  <div key={ index } className="item" onClick={ () => itemClick(item.lat, item.long) }>
                    <h3>{ item.shop_name }</h3>
                    <div className="address">
                      <p>{ item.address_shop }</p>
                      <p>{ item.post_code_shop } { item.city }</p>
                    </div>
                  </div>
                )
              }
            })}
            {results && results.length === 0 && inputValue.length > 2 &&
              <div className="item">
                <h3>Brak wyników wyszukiwania</h3>
              </div>
            }
            { results && results.length > maxResults &&
              <div className="more" onClick={ () => setMaxResults(maxResults + 2) }>
                <Img fluid={images.bottom.childImageSharp.fluid} /><span>Zobacz więcej</span> 
              </div>
            }
          </div>
        </div>
      </section>
    )
}

export default Map;